@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg01: white;
  --primary-color: #029147;
  --bold-color: rgb(41, 79, 30);
  background-color: var(--bg01);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .text-last-center {
    text-align-last: center;
  }
}
